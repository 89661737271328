import React, { useState, useRef, useEffect } from 'react';
import plotLineGraph from "./Plotly";
// import getCenterPoints from './api';

function WebcamRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [lineGraph, setLineGraph] = useState(null);

  // isRecording 값이 변경될 때마다 useEffect를 실행합니다.
  useEffect(() => {
    //   get video permission & Recording
    if (isRecording) {
      setLineGraph(null);
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          const videoTrack = stream.getVideoTracks()[0];
          videoTrack.applyConstraints({ facingMode: 'user' }); // 또는 'environment' 사용 가능

          videoRef.current.srcObject = stream;
          mediaRecorderRef.current = new MediaRecorder(stream);
          videoRef.current.style.transform = 'scaleX(-1)';
          const chunks = [];
          mediaRecorderRef.current.ondataavailable = (e) => {
            if (e.data.size > 0) {
              chunks.push(e.data);
            }
          };

          mediaRecorderRef.current.onstop = () => {
            console.log(chunks)
            const blob = new Blob(chunks, { type: 'video/mp4' });
            const newUrl = URL.createObjectURL(blob);
            // console.log(newUrl);
            setUrl(newUrl);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = newUrl;
            a.download = 'webcam-recording.mp4';

            const formData = new FormData();
            formData.append('video', blob, 'webcam-recording.mp4');
            fetch('https://api.neuroears.co.kr:13002/send-message', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(response => {
                // API 응답을 처리하거나 다음 단계로 이동
                // console.log('API Response:', response);
                setLineGraph(response.data);
              })
              .catch(error => {
                console.error('Error uploading video:', error);
              });
          };
          // start recording
          mediaRecorderRef.current.start();
        })
        .catch((error) => console.error('Error accessing webcam:', error));
    }
  }, [isRecording]);


  const startRecording = () => {
    setUrl(null);
    setIsRecording(true);
  };

  const endRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  return (
    <div id="middler">
      <div id="webcam">
        <div className='box'>
          {/* <p>Recording Status: {isRecording ? 'Recording' : 'Not Recording'}</p> */}
          <div style={{ width: '100%', maxWidth: '320px', overflow: 'hidden' }}>
            {isRecording ? (
              <video ref={videoRef} autoPlay playsInline muted
                style={{ width: '320px', height: "320px", backgroundColor: "black" }} />

            ) : (
              <div style={{ width: '320px', height: '320px', backgroundColor: 'black' }}></div>
            )}
            <img src={process.env.PUBLIC_URL + "face.png"} alt="Overlay Image"
              style={{
                // width: "100%",
                // height: "100%",
                // objectFit: "contain",
                position: "absolute",
                top: 250,
                left: 80,
                width: "200px", height: "200px",
                opacity: 0.3, filter: "invert(100%)"
              }}></img>

          </div>
          <p></p>
          <button onClick={startRecording}>Start Recording.</button>
          <button onClick={endRecording}>Stop Recording.</button>
        </div>
        <div className='box'>
          {url ? (
            <video controls style={{ width: '320px', height: "320px", backgroundColor: "black" }}>
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <div style={{ width: '320px', height: '320px', backgroundColor: 'black' }}></div>
          )
          }
        </div>
      </div>
      <div className='box'>
        {lineGraph && plotLineGraph(lineGraph)}
      </div>
    </div>

  );
}

export default WebcamRecorder;