import React from 'react';
import Plot from 'react-plotly.js'; 
// npm install react-plotly.js plotly.js

const plotLineGraph = (data) => {
    const leftEyeTrace = {
      x: Object.keys(data.left_eye_x).map(frameIndex => parseInt(frameIndex)),
      y: Object.values(data.left_eye_x),
      type: 'scatter',
      mode: 'lines',
      name: 'Left Eye X Data',
    };
    const rightEyeTrace = {
        x: Object.keys(data.left_eye_y).map(frameIndex => parseInt(frameIndex)),
        y: Object.values(data.left_eye_y),
        type: 'scatter',
        mode: 'lines',
        name: 'Left Eye Y Data',
      };
    
    const layout = {
      title: 'Eye Data Over Time',
      xaxis: {
        title: 'Time',
      },
      yaxis: {
        title: 'Eye Value',
      },
    };
    return <Plot data={[leftEyeTrace,rightEyeTrace]} layout={layout} />;
};

export default plotLineGraph;