import React from 'react';
import WebcamRecorder from './utils/Recorder.jsx';
// import logo from './images/logo.png';
import './App.css';

function App() {
  return (
    <div>
      <div id="header">
        <div className="title">
          <h1>Neuroears Diago Demo</h1>
          {/* <div>
            <img src = {logo} width="50%" ></img>
          </div> */}
        </div>
      </div>
      <div id="middler">
        <div className="box">
            <h1>Webcam</h1> 
            <WebcamRecorder />
          </div>
      </div>
      <div id="footer">
      </div>
    </div>
  );
}

export default App;
